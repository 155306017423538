import LogoTransparent from "../components/LogoTransparent";
import "../App.css";
import ReactFullpage from "@fullpage/react-fullpage";
import SvgArrowGradient from "../components/ArrowGradient";
import withDimensions from "../components/withDimensions";
import "../fonts.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import slideTwoBackground from "../assets/paint-splash.jpg";
import slideThreeBackground from "../assets/raindrops.png";
import SvgLogo3D from "../components/Logo3D";
import { InlineWidget } from "react-calendly";
import Footer from "../components/Footer";
import Spline from "@splinetool/react-spline";

const pluginWrapper = () => {
  require("../statics/fullpage.parallax.min");
};

function Home(props) {
  const { width } = props;

  return (
    <ReactFullpage
      licenseKey="0MK6J-V4N58-38HAJ-2VL78-SNZNP"
      pluginWrapper={pluginWrapper}
      credits={false}
      scrollingSpeed={1000}
      responsiveWidth={1281}
      keyboardScrolling={false}
      scrollOverflow={false}
      navigation={true}
      parallax={true}
      parallaxKey="emhjR2w0Wld4d1pYSm1aV04wWkdWemFXZHVMbWx2U3pfNU80Y0dGeVlXeHNZWGc9Mkd6"
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section">
              <header className="App App-header">
                <Spline scene="https://prod.spline.design/BqHWjLYS9ltl1zoP/scene.splinecode" />
              </header>
            </div>
            <div
              className="section fp-auto-height-responsive"
              style={{
                backgroundImage: `url(${slideTwoBackground})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  minWidth: "100vw",
                  minHeight: "100vh",
                  position: "relative",
                  display: "flex",
                  backgroundColor: "#081a39bb",
                }}
                className="section-two-flex"
              >
                <div
                  className="glass card"
                  style={{
                    opacity: "1",
                    maxHeight: "18rem",
                    padding: "0 2rem",
                  }}
                >
                  <h1 className="mega-heading">
                    <span>Let's talk about elevating your brand.</span>
                  </h1>
                </div>

                <div
                  className="glass card calendly-widget"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "0 auto 2rem",
                      width: "90%",
                    }}
                  >
                    Welcome to Pixel Perfect Design and Development, where we
                    specialize in elevating your brand to the next level. Our
                    team of experts offers branding and design services, custom
                    development work, and consulting to help you achieve your
                    goals. Whether you're looking to revamp your website,
                    develop a new application, or just need some guidance on how
                    to take your brand to the next level, we're here to help.
                    Schedule a free consultation with us today and let's chat
                    about how we can help elevate your brand.
                  </p>
                  <InlineWidget
                    url="https://calendly.com/pixel-perfect-design"
                    text="Book an Appointment"
                    styles={{
                      minWidth: "320px",
                      height: "630px",
                      backgroundColor: "#081A39AA",
                      marginLeft: "-0.5rem",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="section fp-auto-height-responsive">
              <div className="App pricing-section">
                <h1 className="section-heading">Custom logo design</h1>
                <p>
                  At Pixel Perfect, we believe that your logo is the foundation
                  of your brand identity. That's why we offer comprehensive logo
                  design services that help you help us create a memorable and
                  timeless logo for your business. We'll work closely with you
                  to develop a logo that represents your business and resonates
                  with your target audience. We also offer color palette design
                  to ensure that your brand has a consistent and cohesive look
                  and feel. Our logo design packages come with social media and
                  branding kits, so you have everything you need to get your
                  brand out there. In addition, we offer copywriting services to
                  help you create a clear and compelling brand message that
                  reflects your brand identity. Schedule a free consultation
                  today to learn more about how we can help you elevate your
                  brand with our logo design services.
                </p>
                <div className="pricing-cards">
                  <div className="pricing-card">
                    <h3>Logo Only</h3>
                    <ul>
                      <li>
                        <i className="fas fa-check"></i>All source files
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>3 Revisions
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>First draft in 5
                        business days
                      </li>
                    </ul>
                    <span>
                      <i>Only</i>
                    </span>
                    <p className="price-tag">$249</p>
                    <a href="/booking">
                      <button className="cta-button">Learn More</button>
                    </a>
                  </div>
                  <div className="pricing-card">
                    <h3>Logo + Social</h3>
                    <ul>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>All source files
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Custom logo design
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>5 revisions
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Social media kit
                      </li>{" "}
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>First draft in 5
                        business days
                      </li>
                    </ul>
                    <span>
                      <i>Only</i>
                    </span>
                    <p className="price-tag">$349</p>
                    <a href="/booking">
                      <button className="cta-button">Learn More</button>
                    </a>
                  </div>
                  <div className="pricing-card">
                    <h3>Full Brand Design</h3>
                    <ul>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>All source files
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Custom logo design
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Unlimited revisions
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Social media kit
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Stationery design
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Branding guidelines
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Bespoke marketing page
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>First draft in 7
                        business days
                      </li>
                    </ul>
                    <span>
                      <i>Starting at</i>
                    </span>
                    <p className="price-tag">$1499</p>
                    <a href="/booking">
                      <button className="cta-button">Learn More</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="section fp-auto-height-responsive">
              <div className="App pricing-section">
                <h1 className="section-heading">
                  Omni-Channel Customer Support Solutions
                </h1>
                <p>
                  As a proud 3CX partner, we offer our clients the ultimate
                  all-in-one customer service platform, trusted by the world's
                  top companies. With our voice, chat, email, and scheduling
                  solutions, your business can take customer engagement to the
                  next level. Good support is more than just being responsive -
                  it's about providing a seamless experience across all
                  channels, making it easy for your customers to get in touch
                  and have their issues resolved quickly. Our solutions offer
                  everything you need to wow your customer. With our Starter,
                  Pro, and Enterprise options, you'll find a solution that fits
                  your budget and requirements, while ensuring customer
                  satisfaction and retention.
                </p>
                <div className="pricing-cards">
                  <div className="pricing-card">
                    <h3>Starter</h3>
                    <ul>
                      <li>
                        <i className="fas fa-check"></i>Cloud-hosted
                        communications solution
                      </li>
                      <li>
                        <i className="fas fa-check"></i>Up to 10 users
                      </li>
                      <li>
                        <i className="fas fa-check"></i>Mobile & desktop apps
                      </li>
                      <li>
                        <i className="fas fa-check"></i>1 ring group
                      </li>
                      <li>
                        <i className="fas fa-check"></i>1 auto attendant
                      </li>
                      <li>
                        <i className="fas fa-check"></i>WhatsApp integration
                      </li>
                      <li>
                        <i className="fas fa-check"></i>Team messaging
                      </li>
                    </ul>
                    <span>
                      <i>Starting at</i>
                    </span>
                    <p className="price-tag">$199/yr</p>
                    <a href="/booking">
                      <button className="cta-button">Learn More</button>
                    </a>
                  </div>
                  <div className="pricing-card">
                    <h3>Pro</h3>
                    <ul>
                      <li>
                        <i className="fas fa-check"></i>Everything in Starter,
                        plus...
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Additional users
                        available
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Multi-level IVR
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Call Queues
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Visual Voicemail
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Reporting
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check"></i>Listen in, whisper,
                        barge in
                      </li>
                    </ul>
                    <span>
                      <i>Starting at</i>
                    </span>
                    <p className="price-tag">$499/yr</p>
                    <a href="/booking">
                      <button className="cta-button">Learn More</button>
                    </a>
                  </div>
                  <div className="pricing-card">
                    <h3>Enterprise</h3>
                    <ul>
                      <li>
                        <i className="fas fa-check"></i>Everything in Starter,
                        plus...
                      </li>
                      <li>
                        <i className="fas fa-check"></i>Additional users
                        available
                      </li>
                      <li>
                        <i className="fas fa-check"></i>Microsoft 365
                        integration
                      </li>
                      <li>
                        <i className="fas fa-check"></i>CRM integration
                      </li>
                      <li>
                        <i className="fas fa-check"></i>SMS & MMS
                      </li>
                      <li>
                        <i className="fas fa-check"></i>Video conferencing with
                        up to 100 participants
                      </li>
                    </ul>
                    <span>
                      <i>Starting at</i>
                    </span>
                    <p className="price-tag">$1999/yr</p>
                    <a href="/booking">
                      <button className="cta-button">Learn More</button>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            <div
              className="section fp-auto-height-responsive"
              style={{
                backgroundImage: `url(${slideThreeBackground})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  minWidth: "100vw",
                  minHeight: "100vh",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#081a39bb",
                }}
                className="section-two-flex"
              >
                <div
                  className="glass card form-widget"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: "3rem",
                  }}
                >
                  <p
                    style={{
                      width: "90%",
                    }}
                  >
                    Thanks for taking the time to visit today! We're dedicated
                    to providing top-notch IT services to help businesses
                    acheive their goals. If you're interested in learning more
                    about how we can help you, sign up below to learn more about
                    our solutions and stay updated on our latest news and
                    promotions. Or, if you're ready to take the next step,
                    schedule a free consultation with us today. We look forward
                    to building something amazing together!
                  </p>
                  <div id="omnisend-embedded-v2-64274fa7158e7babd737b061"></div>
                </div>
              </div>
            </div>
            <div className="section fp-auto-height">
              <Footer />
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}

export default withDimensions(Home);
