import React from "react";

const withDimensions = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        height: window.innerHeight,
        width: window.innerWidth,
        portrait: window.innerHeight > window.innerWidth,
      };
    }

    componentDidMount() {
      window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
      this.setState({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    render() {
      const { height, width, portrait } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          height={height}
          width={width}
          portrait={portrait}
        />
      );
    }
  };
};

export default withDimensions;
